.section {
    width: 100%;
    background-color: rgb(27, 27, 27);
    height: 140px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.image {
    height: 100px;
}

@media screen and (max-width:400px) {
    .image
    {
        height:60px;
    }
}