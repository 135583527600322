.overview
{
    background-color: white;    
}

.background-overview
{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    color: white;
}

.bg-10
{
    background: url('../images/bg10.png') center center/cover no-repeat;
}
.bg-11
{
    background: url('../images/bg11.png') center center/cover no-repeat;
}
.bg-12
{
    background: url('../images/bg12.png') center center/cover no-repeat;
}
.bg-13
{
    background: url('../images/bg13.png') center center/cover no-repeat;
}
.bg-14
{
    background: url('../images/bg14.png') center center/cover no-repeat;
}
.bg-15
{
    background: url('../images/bg15.png') center center/cover no-repeat;
}

.overview-header {
    font-size: 64px;
    letter-spacing: 2px;
}

.overview-header-mini
{
    padding-top: 2%;
    font-size: 52px;
    text-align: center;
    letter-spacing: 2px;
}

.overview-card-container
{
    width: 100%;
}

.overview-paragraph
{
    word-spacing: 5%;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    padding-top: 2%;
    padding-left: 15%;
    padding-right: 15%;
}