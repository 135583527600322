
.supp-container {
    border: #9b5217 5px solid;
    background-color: #fdf2c0;
    width: 80%;
    margin-top: 10%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-bottom: 10%;
    }