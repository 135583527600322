.container {
    padding-left: 30%;
    padding-right: 30%;
}

.footer {
    height: 130px;
}

.footer-container-1 {
    width: 25%;
}

.footer-container-2 {
    width: 50%;
}

.icon {
    width: 30%;
}

.icons {
    padding-left: 10px;
    width: auto;
    height: 40px;
    color: rgb(207, 205, 189);
}

.icons:hover,
.footer-link:hover {
    color: white;
    transition: 0.15s;
}

.footer-link {
    
    text-align: center;
    color: rgb(207, 205, 189);
    text-decoration: none;
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
}

.weight {
    font-weight: 600;
}

.footer-company-icon {
    width: 256px;
}

@media screen and (max-width:960px) {
    .footer-company-icon {
        width: 20vw;
    }
    .footer-link {
        font-size: 1.7vw;
        padding-left: 4px;
        padding-right: 4px;
    }
    .icons {
        padding-left: 5px;
        height: 18px;
    }.footer {
        height: 100px;
    }
}

@media screen and (max-width:400px) {
    .icons {
        height: 8px;
    }
}