@media screen and (max-width:1280px) {
    .leftcard-text-container {
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        margin-top: 250px;
        padding: 50px 50px 50px 50px;
    }

    .leftcard {

        box-sizing: border-box;
        display: flex;
    }
}

@media screen and (min-width:1280px) {
    .leftcard-text-container {
        padding-right: 7%;
        padding-left: 15%;
        padding-top: 70px;
        margin-right: 50%;
        background-color: rgba(0, 0, 0, 0.6);
        text-align: right;
    }

    .leftcard {
        box-sizing: border-box;
        display: flex;
        height: 600px;
    }
}