:root {
  --btn-primary: #442220ff;
  --btn-hover: #2e0014ff;
}

.btn {
  font-weight: bolder;
}

.btn--small {
  padding: 6px 18px;
  font-size: 16px;
}

.btn--xlarge {
  padding: 32px 120px;
  font-size: 36px;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 8px 28px;
  font-size: 24px;
}

.btn--primary {
  background-color: var(--btn-primary);
  border: 1px solid var(--btn-primary);
}

.btn--outline {
  background-color: transparent;
  border: 5px solid var(--btn-primary);
  transition: all 0.3s ease-out;
}

.btn--xlarge:hover,
.btn--small:hover,
.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: var(--btn-hover);
  transition: 250ms;
  cursor: pointer;
}