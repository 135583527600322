@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&family=Lato:wght@300&display=swap');

/* https://coolors.co/2e0014-442220-eee0cb-2d728f-3b8ea5 */

body {
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  margin: 0;
}


.dark-sienna {
  color: #2e0014ff;
}

.dark-sienna-2 {
  color: #442220ff;
}

.orange {
  color: rgb(248, 171, 47);
}

.light-orange {
  color: rgb(255, 226, 181);
}

.white {
  color: white;
}

.border {
  border: 2px greenyellow solid;
}

.champagne {
  color: #EEE0CB;
}

.grulio {
  color: #BAA898;
}

.grey {
  color: #848586;
}

.red-dark {
  color: rgb(255, 143, 7);
}

.bg-1 {
  background-color: #2e0014ff;
}

.bg-2 {
  background-color: #442220ff;
}

.bg-3 {
  background-color: #EEE0CB;
}

.bg-4 {
  background-color: #3b8ea5;
}

.bg-5 {
  background-color: #143340;
}

.bg-6 {
  background-color: #5da271;
}

.title {
  font-family: 'IM Fell English SC', serif;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex-left {
  align-items: center;
  display: flex;
  justify-content: left;
}

.flex-right {
  align-items: center;
  display: flex;
  justify-content: right;
}

.height-100
{
  height: 100%;
}

.brownish {
  color: rgb(207, 205, 189);
}

.text-36 {
  font-size: 36px;
}

.text-24 {
  font-size: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}

.width-50
{
  width: 50%;
}

.page-bg {
  background: url("../src/images/main.avif");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

@font-face {
  font-family: "Alagard";
  src: local("Alagard"),
    url("./fonts/alagard.ttf") format("truetype");
  font-weight: bold;
}