.trailer {
    background: linear-gradient(rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.1)100%), url('../images/main.avif');
    background-color: rgb(54, 34, 18);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 110%;
    position: relative;
    display: flex;
    background-size: 2000px;
}

.video-trailer {
    width: 80vw;
    height: 50vw;

    max-width: 45vw;
    max-height: 25vw;
}


.top-container {
    padding-top: 50px;
    padding-bottom: 25px;
}

.trailer-text {
    width: 50%;
}

.trailer-btn {
    padding-top: 25px;
}
.trailer-img {
    max-width: 100%;
}

@media screen and (max-width:960px) {
    .trailer-img {
        max-width: 70%;
    }
    .trailer {
        background-size: 600%;
    }
    .trailer-text
    {
        width: 80%;
        font-size: 2rem;
    }
}

@media screen and (max-width:400px) {
    .trailer-text
    {
        font-size: 18px;
    }
}