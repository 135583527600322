.navbar {
  height: 60px;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.nav-icons:hover {
  transition: all 0.2s ease-out;
  color: rgb(80, 40, 8);
}

.menu-icon {
  color: white;
  padding-right: 14px;
}

.menu-icon:hover {
  transition: all 0.2s ease-out;
  color: #be9c79;
  cursor: pointer;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  margin-right: 2rem;
}


.nav-links {
  color: rgb(252, 253, 224);
  text-decoration: none;
  font-weight: 600;
}

.nav-links:hover {
  transition: all 0.2s ease-out;
  color: rgb(134, 123, 105);
}


ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
}

li {
  float: right;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .navbar {
    height: 60px;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .navbar-menu {
    width: 100%;
    z-index: 999;
  }

  .navbar-logo {
    width: 10px;
    padding-left: 12px;
  }

  .navbar-ul {
    padding: 0px 0px 0px;
    overflow-x: hidden;
  }

  .navbar-icon {
    width: 60px;
  }

  .nav-links {
    text-decoration: none;
    color: #A9927D;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .nav-links:hover {
    color: #e9d8c8;
    transition: 0.2s;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    height: 100%;
  }

  li {
    border-top: none;
    padding: 6px 0 6px;
    width: 100%;
    text-decoration: none;
    text-align: center;
  }

  .li-border {
    border: 0.5px #5E503F solid;

  }
}