@media screen and (max-width:1280px) {
  .rightcard-text-container {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    margin-top: 250px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }

  .rightcard {

    box-sizing: border-box;
    display: flex;
  }

  .card-p {
    font-size: 2.5vh;
    line-height: 4vh;
  }

  .card-h1 {
    font-size: 5vh;
  }

  .card-h2 {
    font-size: 4vh;
  }
}

@media screen and (min-width:1280px) {
  .rightcard-text-container {
    padding-right: 7%;
    padding-left: 15%;
    padding-top: 70px;
    margin-left: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: right;
  }

  .rightcard {

    box-sizing: border-box;
    display: flex;
    height: 600px;
  }

  .card-p {
    font-size: 18px;
    line-height: 28px;
  }

  .card-h1 {
    font-size: 48px;
  }

  .card-h2 {
    font-size: 32px;
  }
}

@media screen and (max-width:400px) {
  .card-p {
    font-size: 10px;
    line-height: 20px;
  }

  .card-h1 {
    font-size: 24px;
  }

  .card-h2 {
    font-size: 24px;
  }
}